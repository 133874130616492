
/* --- SLIDER --- */
module.exports = () => {

	jQuery(document).ready(function () {

		jQuery('.slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 3,
			slidesToScroll: 3,
			arrows: false,
			centerPadding: '40px',
			autoplay: true,
			autoplaySpeed: 2000,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 2
					}
				},
				{
					breakpoint: 540,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});

		// our services
		jQuery('.tiles-flex-slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 901,
					settings: "unslick"
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2.2,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.tiles-flex-slider').slick('resize');
		});

		jQuery('.reviews-slider').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 900,
					settings: {
						slidesToShow: 3,
						slidesToScroll: 1
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 2,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.reviews-slider').slick('resize');
		});



		jQuery('.logos-slider').slick({
			speed: 9000,
			autoplay: true,
			autoplaySpeed: 0,
			cssEase: 'linear',
			slidesToShow: 1,
			slidesToScroll: 1,
			variableWidth: true,
			arrows: false
		}); 


		jQuery('.posts').slick({
			dots: true,
			infinite: false,
			speed: 500,
			slidesToShow: 1,
			slidesToScroll: 1,
			arrows: false,
			centerPadding: '40px',
			autoplay: false,
			autoplaySpeed: 2000,
			mobileFirst: true,
			responsive: [
				{
					breakpoint: 900,
					settings: "unslick"
				},
				{
					breakpoint: 500,
					settings: {
						slidesToShow: 2.1,
						slidesToScroll: 1
					}
				}
				// You can unslick at a given breakpoint now by adding:
				// settings: "unslick"
				// instead of a settings object
			]
		});
		jQuery(window).resize(function(){
			jQuery('.posts').slick('resize');
		});


	});
}