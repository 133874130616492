
module.exports = () => {

    document.addEventListener('DOMContentLoaded', function() {


        function isScrolledIntoView(el) {
            var rect = el.getBoundingClientRect();
            var elemTop = rect.top;
            var elemBottom = rect.bottom;
        
            // Only completely visible elements return true:
            // var isVisible = (elemTop >= 0) && (elemBottom <= window.innerHeight);
            // Partially visible elements return true:
            isVisible = elemTop < window.innerHeight && elemBottom >= 0;
            return isVisible;
        }


        let numbersContainers = document.querySelectorAll('.numbers_sequence');


        function animateNumber(numberEl) {
            let value = Number(numberEl.dataset.value);

            let animationDuration = 1500;
            let intervalDuration;
            let increment;

            if (value < animationDuration) {
                intervalDuration = animationDuration / value;
                increment = 1;
            } else {
                intervalDuration = 20;
                increment = value / 100;
            }

            let interval = window.setInterval(() => {
                if (Number(numberEl.innerHTML.replace(',', '')) >= value) {
                    clearInterval(interval);
                    numberEl.innerHTML = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                } else {
                    let oldNum = Number(numberEl.innerHTML.replace(',',''));
                    let newNum = oldNum + increment;
                    numberEl.innerHTML = newNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
            }, intervalDuration)
        }

        const checkNumbers = function() {
            // for every numbers layout
            for(let i=0; i<numbersContainers.length; i++) {
                let numbers_sequence = numbersContainers[i];

                // check if it's in view and not already animated
                if (isScrolledIntoView(numbers_sequence) && numbers_sequence.dataset.animated !== 'true') {
                    numbers_sequence.dataset.animated = 'true';

                    // animate numbers on every card within numbers layout
                    let numbers = numbers_sequence.querySelectorAll('.number-anim');
                    for (let i=0; i<numbers.length; i++) {
                        let numberEl = numbers[i];
                        animateNumber(numberEl);
                    }

                }
            }
        }

        checkNumbers();
        document.onscroll = function() {
            checkNumbers();
        }

    })
}